<template>
  <div class="main-part-wrapper">
    <navbar msg="" back-route="/" />
    <div class="bg" />
    <div class="inner">
      <section class="top-btn">
        <router-link :to="{ name: 'EventNew' }" class="top-btn__half">
          <div
            :class="[
              'top-btn__btn',
              $route.path.startsWith('/store/event/new/add') && 'topBtn__active'
            ]"
          >
            <svg
              width="512px"
              height="512px"
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
              viewBox="0 0 512 512"
            >
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  d="M256 0l0 0c18.48,0 33.61,15.12 33.61,33.6l0 444.8c0,18.48 -15.13,33.6 -33.61,33.6l0 0c-18.49,0 -33.61,-15.12 -33.61,-33.6l0 -444.8c0,-18.48 15.12,-33.6 33.61,-33.6z"
                />
                <path
                  d="M512 256l0 0c0,18.48 -15.12,33.61 -33.6,33.61l-444.8 0c-18.48,0 -33.6,-15.13 -33.6,-33.61l0 0c0,-18.49 15.12,-33.61 33.6,-33.61l444.8 0c18.48,0 33.6,15.12 33.6,33.61z"
                />
              </g>
            </svg>
          </div>
          <span class="top-btn__text">افزودن</span>
        </router-link>
        <i class="top-btn__sep" />
        <router-link :to="{ name: 'EventHome' }" class="top-btn__half">
          <div
            :class="[
              'top-btn__btn',
              $route.path.startsWith('/store/event/new/edit') &&
                'topBtn__active'
            ]"
          >
            <svg
              width="512px"
              height="512px"
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
              viewBox="0 0 512 512"
            >
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  d="M34 273.56l170.44 0c18.71,0 34,15.29 34,34l0 170.44c0,18.7 -15.29,34 -34,34l-170.44 0c-18.7,0 -34,-15.3 -34,-34l0 -170.44c0,-18.71 15.3,-34 34,-34zm34 68l0 102.44 102.44 0 0 -102.44 -102.44 0zm273.55 0l0 102.44 102.45 0 0 -102.44 -102.45 0zm0 -273.56l0 102.44 102.45 0 0 -102.44 -102.45 0zm-273.55 0l0 102.44 102.44 0 0 -102.44 -102.44 0zm239.55 205.56l170.45 0c18.7,0 34,15.29 34,34l0 170.44c0,18.7 -15.3,34 -34,34l-170.45 0c-18.7,0 -34,-15.3 -34,-34l0 -170.44c0,-18.71 15.3,-34 34,-34zm0 -273.56l170.45 0c18.7,0 34,15.3 34,34l0 170.44c0,18.71 -15.3,34 -34,34l-170.45 0c-18.7,0 -34,-15.29 -34,-34l0 -170.44c0,-18.7 15.3,-34 34,-34zm-273.55 0l170.44 0c18.71,0 34,15.3 34,34l0 170.44c0,18.71 -15.29,34 -34,34l-170.44 0c-18.7,0 -34,-15.29 -34,-34l0 -170.44c0,-18.7 15.3,-34 34,-34z"
                />
              </g>
            </svg>
          </div>
          <span class="top-btn__text">همه</span>
        </router-link>
        <i class="top-btn__sep" />
        <router-link :to="{ name: 'EventStat' }" class="top-btn__half">
          <div class="top-btn__btn">
            <svg width="512px" height="512px" viewBox="0 0 512 512">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2052902131264">
                  <path
                    d="M256 0l0 0c18.48,0 33.61,15.12 33.61,33.6l0 444.8c0,18.48 -15.13,33.6 -33.61,33.6l0 0c-18.49,0 -33.61,-15.12 -33.61,-33.6l0 -444.8c0,-18.48 15.12,-33.6 33.61,-33.6zm136.4 113.79l0 0c18.49,0 33.61,15.11 33.61,33.6l0 331.01c0,18.48 -15.12,33.6 -33.61,33.6l0 0c-18.49,0 -33.61,-15.12 -33.61,-33.6l0 -331.01c0,-18.49 15.12,-33.6 33.61,-33.6zm-272.8 134l0 0c18.49,0 33.61,15.12 33.61,33.61l0 197c0,18.48 -15.12,33.6 -33.61,33.6l0 0c-18.49,0 -33.61,-15.12 -33.61,-33.6l0 -197c0,-18.49 15.12,-33.61 33.61,-33.61z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <span class="top-btn__text">آمار</span>
        </router-link>
      </section>
      <router-view />
    </div>
    <fixed-footer />
  </div>
</template>

<script>
export default {
  name: 'Event'
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url(~@img/event-bg.svg);
}
.router-link-exact-active .top-btn__btn {
  background-color: #c69d4e;
}
.top-btn__btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 21px;
    height: 21px;
  }
}
.tri-icon {
  position: relative;
  left: 2.7px;
}
</style>
