<template>
  <div class="stat-wrapper">
    <section class="load-pending" v-if="pageStatus == 'loading'">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div class="chartTitle">
        <h3 class="chartTitle__text">تعداد پیام های ارسال شده</h3>
        <h4 class="chartTitle__number">
          {{ stat.count ? stat.count.toLocaleString() : 0 }}
        </h4>
      </div>
      <div class="stat-table">
        <div class="stat-table__row column--four header--row">
          <h5 class="stat-table__cell">تاریخ</h5>
          <h5 class="stat-table__cell">زمان</h5>
          <h5 class="stat-table__cell">تعداد</h5>
          <h5 class="stat-table__cell">آخرین خرید</h5>
        </div>
        <div
          v-for="(event, idx) in reminderLogs"
          :key="event.id"
          :class="['stat-table__row', idx % 2 === 0 && 'even--row']"
        >
          <h5 class="stat-table__cell">
            {{ localizeDate(event['created_at']) }}
          </h5>
          <h5 class="stat-table__cell">{{ getHour(event['created_at']) }}</h5>
          <h5 class="stat-table__cell">
            {{ event['count'].toLocaleString() }}
          </h5>
          <h5 class="stat-table__cell cell--fa-text">
            {{ event['day'] }}
            روز
          </h5>
        </div>
        <div class="stat-table__row">
          <h5 class="stat-table__cell">
            ارسال نشده
          </h5>
          <h5 class="stat-table__cell">0</h5>
          <h5 class="stat-table__cell">
            0
          </h5>
          <h5 class="stat-table__cell cell--fa-text">
            0
          </h5>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'

export default {
  name: 'EventStat',
  setup() {
    const { localizeDate, getHour } = dateConvertor()
    const store = useStore()
    store.dispatch('fetchStat', { part: 'reminder' })
    const stat = computed(() => store.state.stat)
    // use to resolved data fetching to show page
    const pageStatus = ref('loading')
    store.dispatch('reminder/fetchReminderLogs')
    const reminderLogs = computed(() => store.state.reminder.logs)

    watchEffect(() => {
      if (stat.value && stat.value.count >= 0) pageStatus.value = 'resolved'
    })

    onBeforeUnmount(() => store.dispatch('clearStat'))
    return { reminderLogs, localizeDate, getHour, stat, pageStatus }
  }
}
</script>

<style scoped lang="scss">
// table
.stat-table__row {
  grid-template-columns: repeat(4, 1fr);
}
.cell--small {
  font-size: 14px;
}
</style>
