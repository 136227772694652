<template>
  <div :class="['event', eventType === 'static' && 'event--static']">
    <div class="event__right" v-if="eventType === 'dynamic'">
      <div class="event__left-time">
        <p class="event__time">
          <span class="event__time-count">{{ Math.abs(dayLeft) }}</span> روز
          مانده
        </p>
      </div>
      <div class="event__date">
        <div class="event__box">
          <h5 class="event__date-day">{{ dateJalali.split(' ')[0] }}</h5>
          <h6 class="event__date-month">{{ dateJalali.split(' ')[1] }}</h6>
        </div>
        <div class="event__box">
          <h5 class="event__date-day">{{ dateHijri.split(' ')[0] }}</h5>
          <h6 class="event__date-month">{{ dateHijri.split(' ')[1] }}</h6>
        </div>
        <div class="event__box">
          <h5 class="event__date-day">{{ dateGregorian.split(' ')[0] }}</h5>
          <h6 class="event__date-month">{{ dateGregorian.split(' ')[1] }}</h6>
        </div>
      </div>
      <h3 class="event__title">{{ dayName }}</h3>
    </div>
    <div class="event__left">
      <img class="event__img" :src="img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventSingle',
  props: {
    status: {
      type: String,
      default: '2'
    },
    dayName: {
      type: String,
      required: true
    },
    img: {
      type: String
    },
    type: {
      /**
       * 2 types
       * 1 => rotational based on events in a day
       * 2 => static like birthday
       */
      type: String,
      default: '2'
    },
    time: {
      // time of event
      type: String
    },
    dateJalali: {
      type: String
    },
    dateGregorian: {
      type: String
    },
    dateHijri: {
      type: String
    }
  },
  computed: {
    dayLeft() {
      const oneDay = 24 * 60 * 60 * 1000
      return Math.round((new Date() - new Date(this.time)) / oneDay)
    },
    eventType() {
      return this.type === '2' ? 'dynamic' : 'static'
    }
  }
}
</script>

<style lang="scss" scoped>
.event {
  width: 94%;
  height: 150px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 10px;
  overflow: hidden;
  cursor: pointer;
}
.event__title {
  padding-right: 10px;
  width: 100%;
  margin: 0;
  font-size: 14px;
  color: #000;
  text-align: right;
}
.event__right {
  display: grid;
  grid-template-rows: 1fr 70px 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  width: calc(100% - 150px);
  height: 100%;
}
.event__left-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.event__time {
  width: 130px;
  padding-right: 10px;
  color: #000;
  text-align: right;
  margin: 0;
  animation: blinking 1.3s infinite;
}
.event__time-count {
  color: #c69d4e;
  font-weight: bold;
}
.event__date {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  padding: 0 6px 0 6px;
}
.event__box {
  min-width: 70px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.1);
  color: #000;
}

.event__date-day {
  margin: 0 0 0;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  top: 2px;
}
.event__date-month {
  margin: 0;
  font-size: 11px;
}
.event__left {
  position: relative;
  height: 100%;
  // overflow: hidden;
}
.event__img {
  width: 150px;
  position: absolute;
  left: 0;
  top: 0;
  // width: 100%;
  height: 100%;
}

// static modifier
.event--static {
  // overflow: initial;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.event--static .event__left {
  grid-column: 2/3;
}

.event--static .event__img {
  width: auto;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
