<template>
  <section class="list list--reminder">
    <reminder-single
      v-for="reminder in reminders"
      :key="reminder.id"
      :day="reminder['last_buy']"
      @click="enterReminder(reminder.id)"
    />
  </section>
  <empty-part
    desc-top="یادآوری"
    desc-bottom="یادآوری"
    v-if="pageStatus == 'resolved' && reminders.length === 0"
  />
  <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
  <loader v-if="pageStatus == 'loading'" />
  <observer @intersect="intersected" />
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import EmptyPart from '@common/EmptyPart'
import ReminderSingle from './subComponents/ReminderSingle'

export default {
  name: 'ReminderHome',
  components: {
    ReminderSingle,
    EmptyPart
  },
  setup() {
    // Fetch Bonuses
    const store = useStore()
    store.dispatch('reminder/fetchItems', { part: 'reminder' })
    const reminders = computed(() => store.state.reminder.items)
    const pageStatus = computed(() => store.state.reminder.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('reminder/fetchItems', { part: 'reminder' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterReminder = (id) => {
      router.push({ name: 'ReminderNew', params: { id, mode: 'edit' } })
    }

    // Clear offers
    onBeforeUnmount(() => store.dispatch('reminder/clearItems'))

    // ------- //
    return { reminders, intersected, enterReminder, pageStatus }
  }
}
</script>

<style lang="scss" scoped>
.list--reminder {
  width: 100%;
  padding: 10px 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
