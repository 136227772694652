<template>
  <div
    id="img-dropzone"
    class="dropzone-container"
    @dragover.prevent
    @drop.prevent
  >
    <div class="file-wrapper">
      <div class="logo">
        <svg viewBox="0 0 512 512">
          <path
            d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
          />
          <path
            d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
          />
        </svg>
      </div>
      <input
        type="file"
        name="file-input"
        accept="image/*"
        @change="(e) => $emit('imgselected', e)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFileInput',
  emits: ['imgselected']
}
</script>

<style lang="scss" scoped>
.dropzone-container {
  // border: 2px solid #aaa;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  .file-wrapper {
    border-radius: 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #eee;
    // box-shadow: 0 0 10px 1px rgba(#000, 0.3);
    input {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      cursor: pointer;
      opacity: 0;
      height: 100%;
      width: 100%;
    }
    .logo {
      margin-top: 0;
      width: 92%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      svg {
        width: 60px;
        fill: #777;
      }
    }
  }
}
</style>
