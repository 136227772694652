<template>
  <div class="reminder">
    <i class="reminder__hook hook--left" />
    <i class="reminder__hook hook--right" />
    <div class="reminder__top">
      <h3 class="reminder__time" v-text="day" />
    </div>
    <div class="reminder__bottom">
      <h4 class="reminder__title">reminder</h4>
      <svg class="reminder__icon" viewBox="0 0 750 512">
        <path
          d="M375 0c178.96,0 324.03,94.38 324.03,210.8 0,70.62 -53.39,133.12 -135.33,171.37 -8.05,3.76 -12,6.91 -19.39,3.42 -9.62,-4.53 -9.7,-16.41 -1.02,-22.87 5.99,-4.46 14.88,-7.45 20.71,-10.43 65.82,-33.66 107.76,-84.54 107.76,-141.49 0,-55.57 -39.93,-105.37 -103.02,-139.02 13.24,16.3 20.66,34.53 20.66,53.78 0,69.3 -95.99,125.47 -214.4,125.47 -118.41,0 -214.4,-56.17 -214.4,-125.47 0,-19.25 7.42,-37.48 20.66,-53.78 -63.09,33.65 -103.02,83.45 -103.02,139.02 0,101.36 132.86,183.54 296.76,183.54 13.28,0 26.36,-0.55 39.18,-1.59l-65.22 -65.23c-5.29,-5.29 -5.3,-13.96 0,-19.26l0 0c5.3,-5.3 13.97,-5.3 19.27,0l87.24 87.25c2.76,2.75 4.13,9.3 4.21,12.62 -0.08,3.33 -1.45,9.87 -4.21,12.63l-87.26 87.27c-5.3,5.29 -13.97,5.29 -19.27,0l0 0c-5.3,-5.31 -5.29,-13.97 0,-19.27l69 -69c-14.05,1.21 -28.38,1.84 -42.94,1.84 -178.96,0 -324.03,-94.38 -324.03,-210.8 0,-116.42 145.07,-210.8 324.03,-210.8zm0 27.36c-44.03,0 -92.2,8.57 -131.38,29.42 -22.96,12.21 -50.32,33.27 -55.06,60.65 -0.46,2.69 -0.7,5.41 -0.7,8.13 0,2.73 0.24,5.45 0.7,8.14 4.74,27.37 32.1,48.43 55.06,60.65 39.18,20.84 87.35,29.41 131.38,29.41 44.03,0 92.2,-8.57 131.38,-29.41 22.95,-12.22 50.32,-33.28 55.05,-60.65 0.47,-2.69 0.71,-5.41 0.71,-8.14 0,-2.72 -0.24,-5.44 -0.71,-8.13 -4.73,-27.38 -32.1,-48.44 -55.05,-60.65 -39.18,-20.85 -87.35,-29.42 -131.38,-29.42z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReminderSingle',
  props: {
    day: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
$reminder-radius: 20px;

.reminder {
  width: 100%;
  height: 160px;
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
}
.reminder__hook {
  z-index: 2;
  position: absolute;
  height: 40px;
  width: 20px;
  border-radius: 20px;
  background-color: #eee;
  top: -20px;
}
.reminder__hook::after {
  content: '';
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 10px;
  height: 30px;
  border-radius: 20px;
  background-color: #fff;
}
.hook--left {
  left: 25%;
}
.hook--right {
  right: 25%;
}
.reminder__top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-radius: $reminder-radius;
  z-index: 1;
  background-color: #fff;
}
.reminder__time {
  width: 100%;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  position: relative;
  top: 8px;
}
.reminder__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 100%;
  height: 70px;
  margin-top: -30px;
  padding-top: 30px;
  border-radius: 0 0 $reminder-radius $reminder-radius;
}
.reminder__icon {
  width: 30px;
  height: 30px;
  fill: #c69d4e;
  margin-right: 10px;
}
.reminder__title {
  text-transform: uppercase;
  color: #fff;
}
</style>
