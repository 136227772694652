<template>
  <section class="list">
    <event-single
      v-for="event in eventStatic"
      :key="event['id']"
      :status="event['status']"
      :img="event['image']"
      :day-name="event['title']"
      :type="event['type']"
      :time="event['run_at']"
      @click="() => enterEvent(event['id'])"
    />
    <event-single
      v-for="event in eventDynamic"
      :key="event['id']"
      :status="event['status']"
      :img="event['image']"
      :day-name="event['title']"
      :type="event['type']"
      :time="event['run_at']"
      :dateJalali="event['date_jalali']"
      :dateGregorian="event['date_gregorian']"
      :dateHijri="event['date_hijri']"
      @click="() => enterEvent(event['id'])"
    />
    <!-- <empty-part
      desc-top="پیام مناسبتی"
      desc-bottom="پیام مناسبتی"
      v-if="pageStatus == 'resolved' && events.length === 0"
    /> -->
    <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
    <loader v-show="pageStatus == 'loading'" />
    <observer @intersect="intersected" />
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import { dateMixin } from '@mixin/dateMixin'
import EventSingle from './subComponents/EventSingle'

export default {
  name: 'EventNew',
  components: {
    EventSingle
  },
  mixins: [dateMixin],
  setup() {
    // Fetch Lotteries
    const store = useStore()
    store.dispatch('event/fetchItems', {
      part: 'event',
      query: { status: 'inactive' }
    })
    const pageStatus = computed(() => store.state.event.pageStatus)

    const events = computed(() => store.state.event.items)
    const eventStatic = computed(() =>
      events.value.filter((item) => item.type == 1)
    )
    const eventDynamic = computed(() =>
      events.value.filter((item) => item.type == 2)
    )

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('event/fetchItems', {
        part: 'event',
        query: { status: 'inactive' }
      })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterEvent = (id) => {
      router.push({ name: 'EventEdit', params: { id, mode: 'add' } })
    }

    // Clear events
    onBeforeUnmount(() => store.dispatch('event/clearItems'))

    // ------- //
    return {
      events,
      eventStatic,
      eventDynamic,
      pageStatus,
      intersected,
      enterEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
