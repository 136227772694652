<template>
  <div class="message-part-wrapper">
    <loader v-if="pageStatus == 'loading'" />
    <form
      novalidate
      class="input-wrapper"
      v-else-if="pageStatus == 'resolved'"
      @submit.prevent="submitForm"
    >
      <div
        :class="['event', eventType === 'static' && 'event--short']"
        v-if="event"
      >
        <h1 class="event__title">{{ event['title'] }}</h1>
        <p class="event__time" v-if="event['type'] == '1'">
          ساعت 8 صبح به متولدین هر روز ارسال می ‌شود
        </p>
        <p class="event__time" v-else-if="event['type'] == '2'">
          ساعت 8 صبح
          <span class="event__time-count">{{ dayLeft }}</span>
          روز دیگر ارسال می ‌شود
        </p>
        <div class="event__date" v-if="event['type'] == '2'">
          <div class="event__box">
            <h5 class="event__date-day">
              {{ event['date_jalali'].split(' ')[0] }}
            </h5>
            <h6 class="event__date-month">
              {{ event['date_jalali'].split(' ')[1] }}
            </h6>
          </div>
          <div class="event__box">
            <h5 class="event__date-day">
              {{ event['date_hijri'].split(' ')[0] }}
            </h5>
            <h6 class="event__date-month">
              {{ event['date_hijri'].split(' ')[1] }}
            </h6>
          </div>
          <div class="event__box">
            <h5 class="event__date-day">
              {{ event['date_gregorian'].split(' ')[0] }}
            </h5>
            <h6 class="event__date-month">
              {{ event['date_gregorian'].split(' ')[1] }}
            </h6>
          </div>
        </div>
      </div>
      <div class="has-name">
        <p class="has-name__text">
          "نام مشتری" به ابتدای پیام افزوده ‌شود؟
        </p>
        <span class="has-name__ex">
          مثال: "خانم سارا محمدی عزیز" یا " آقای علی رضایی عزیز"
        </span>
        <div class="credit-buttons" style="margin: 0">
          <button
            @click="formItems['eventHasName'] = !formItems['eventHasName']"
            type="button"
            :class="[
              'button',
              'button--default',
              formItems['eventHasName'] && 'button--active'
            ]"
          >
            <span class="button__text">
              {{ formItems['eventHasName'] ? 'بله' : 'خیر' }}
            </span>
          </button>
        </div>
      </div>
      <div class="input-row">
        <a class="message-float-btn" @click="modals['message'] = true">
          متن های آماده
        </a>
        <box-textarea label="متن پیام" v-model="formItems['eventMessage']" />
      </div>
      <a class="img" @click.self="modals['image'] = true">
        <img
          class="img__img"
          v-if="!!imgBase64 && imgAction === 'select'"
          :src="imgBase64['image']"
        />
        <img
          class="img__img"
          v-else-if="!!imgBase64 && imgAction === 'upload'"
          :src="imgBase64"
        />
        <div class="img__outer" v-else>
          <div class="img__inner">
            <svg class="img__icon" viewBox="0 0 512 512">
              <path
                d="M446.575,0H65.425C29.349,0,0,29.35,0,65.426v381.149C0,482.65,29.349,512,65.425,512h381.15
			C482.651,512,512,482.65,512,446.574V65.426C512,29.35,482.651,0,446.575,0z M481.842,446.575
			c0,19.447-15.821,35.267-35.267,35.267H65.425c-19.447,0-35.268-15.821-35.268-35.267v-55.007l99.255-84.451
			c3.622-3.082,8.906-3.111,12.562-0.075l62.174,51.628c5.995,4.977,14.795,4.569,20.304-0.946L372.181,209.77
			c2.67-2.675,5.783-2.935,7.408-2.852c1.62,0.083,4.695,0.661,7.078,3.596l95.176,117.19V446.575z M481.842,279.865l-71.766-88.366
			c-7.117-8.764-17.666-14.122-28.942-14.701c-11.268-0.57-22.317,3.672-30.294,11.662L212.832,326.681l-51.59-42.839
			c-14.959-12.422-36.563-12.293-51.373,0.308l-79.712,67.822V65.426c0-19.447,15.821-35.268,35.268-35.268h381.15
			c19.447,0,35.267,15.821,35.267,35.268V279.865z"
              />
              <path
                d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713
			S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556
			c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
              />
            </svg>
          </div>
        </div>
        <button
          class="img__remove"
          v-if="!!imgBase64"
          type="button"
          @click="removeImg"
        >
          &times;
        </button>
      </a>
      <!-- <div class="table table--form" style="width: 100%; margin: 30px 0 0;">
        <div class="table__row">
          <h6 class="table__cell cell--title">
            ارسال هر پیام
          </h6>
          <h6 class="table__cell cell--price ">
            {{ eventTablePrice.toLocaleString() }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row row--desc">
          مبلغ ارسال پیام ‌ها در زمان ارسال، به ازای تعداد مشتریان محاسبه شده و
          از موجودی پرداخت می‌گردد.
        </div>
      </div> -->
      <div class="credit-buttons buttons--add" v-if="mode === 'add'">
        <button type="submit" class="button button--black">
          <span class="button__text">فعالسازی</span>
          <spinner v-if="btnLoading" />
        </button>
      </div>
      <div class="round-btns" v-else>
        <button type="button" class="round-btn" @click="disableEvent">
          <svg
            class="round-btn__icon"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 700 512"
          >
            <path
              d="M279.81 1.13l140.38 0c8.66,0 15.75,7.08 15.75,15.74l0 0c0,8.66 -7.09,15.75 -15.75,15.75l-140.38 0c-8.66,0 -15.75,-7.09 -15.75,-15.75l0 0c0,-8.66 7.09,-15.74 15.75,-15.74zm254.83 99.61l0 358.4c0,28.45 -23.28,51.73 -51.73,51.73l-267.02 0c-28.45,0 -51.73,-23.28 -51.73,-51.73l0 -358.4 -39.2 0c-8.65,0 -15.74,-7.08 -15.74,-15.74l0 0c0,-8.66 7.09,-15.74 15.74,-15.74l450.08 0c8.65,0 15.74,7.08 15.74,15.74l0 0c0,8.66 -7.09,15.74 -15.74,15.74l-40.4 0zm-258.35 292.43l0 -207.33c0,-8.67 7.08,-15.75 15.74,-15.75l0 0c8.66,0 15.75,7.08 15.75,15.75l0 207.33c0,8.66 -7.09,15.75 -15.75,15.75l0 0c-8.66,0 -15.74,-7.09 -15.74,-15.75zm114.38 0l0 -207.33c0,-8.67 7.08,-15.75 15.74,-15.75l0 0c8.66,0 15.74,7.08 15.74,15.75l0 207.33c0,8.66 -7.08,15.75 -15.74,15.75l0 0c-8.66,0 -15.74,-7.09 -15.74,-15.75zm112.48 -292.43l0 350.64c0,15.4 -12.6,28 -28,28l-251.5 0c-15.4,0 -28,-12.6 -28,-28l0 -350.64 307.5 0z"
            />
          </svg>
          <span class="round-btn__text">لغو ارسال</span>
        </button>
        <button type="submit" class="round-btn">
          <svg class="round-btn__icon" viewBox="0 0 512 512">
            <path
              d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
            />
          </svg>
          <span class="round-btn__text">ثبت تغییرات</span>
        </button>
        <router-link class="round-btn" :to="{ name: 'EventHome' }">
          <svg
            class="round-btn__icon"
            viewBox="0 0 512 512"
            style="transform: rotate(90deg);width: 27px; height: 27px;"
          >
            <path
              d="M440.437,301.781L266.656,475.584V10.667C266.656,4.776,261.88,0,255.989,0
	c-5.891,0-10.667,4.776-10.667,10.667v464.917L71.541,301.781c-4.237-4.093-10.99-3.975-15.083,0.262
	c-3.992,4.134-3.992,10.687,0,14.82l192,192c4.16,4.171,10.914,4.179,15.085,0.019c0.006-0.006,0.013-0.013,0.019-0.019l192-192
	c4.093-4.237,3.975-10.99-0.262-15.083c-4.134-3.993-10.687-3.993-14.821,0L440.437,301.781z"
            />
            <path
              d="M255.989,512c-2.831,0.005-5.548-1.115-7.552-3.115l-192-192c-4.093-4.237-3.975-10.99,0.262-15.083
	c4.134-3.992,10.687-3.992,14.82,0l184.469,184.448l184.448-184.448c4.237-4.093,10.99-3.975,15.083,0.262
	c3.993,4.134,3.993,10.687,0,14.821l-192,192C261.521,510.879,258.813,511.999,255.989,512z"
            />
            <path
              d="M255.989,512c-5.891,0-10.667-4.776-10.667-10.667V10.667C245.323,4.776,250.098,0,255.989,0
	c5.891,0,10.667,4.776,10.667,10.667v490.667C266.656,507.224,261.88,512,255.989,512z"
            />
          </svg>

          <span class="round-btn__text">بازگشت</span>
        </router-link>
      </div>
    </form>
    <failed-mode v-else />
    <modal
      :is-open="modals['message']"
      @modalClosed="modals['message'] = false"
      height="80%"
      title="متن‌های آماده"
    >
      <div class="available-messages" v-if="!!samples">
        <template v-for="message in samples" :key="message">
          <div class="message-row" @click="() => messageClicked(message)">
            <h5>{{ message['text'] }}</h5>
          </div>
          <hr />
        </template>
      </div>
    </modal>
    <modal
      :is-open="modals['image']"
      @modalClosed="modals['image'] = false"
      height="400px"
    >
      <div class="modal-image">
        <div class="available-images">
          <div class="modal__img" v-for="img in imgSamples" :key="img.id">
            <img :src="img.image" @click="handleSelectGalleryImg(img)" />
          </div>
          <div class="upload-wrapper">
            <base-file-input class="file-input" @imgselected="receiveImage" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getSamples } from '@service/CoreService'
import { getSampleImages, addSampleImages } from '@service/NoticeService'
import { changeEvent, deleteEvent } from '@service/EventService'
import { schemaMaker, reqString, validateForms } from '@valid/JoiValidation'
import BaseFileInput from '@common/BaseFileInput'
import BoxTextarea from '@common/BoxTextarea'
import imgResizer from '@composable/imgResizer'

const isFormValid = schemaMaker({
  message: reqString('پیام')
})

export default {
  name: 'EventEdit',
  props: ['id', 'mode'],
  components: {
    BaseFileInput,
    BoxTextarea
  },
  setup() {
    // #TODO rewrite with composition api
    const imgResizerItems = imgResizer()

    return { ...imgResizerItems }
  },
  data() {
    return {
      modals: {
        message: false,
        image: false
      },
      formItems: {
        eventMessage: '',
        eventHasName: false
      },
      samples: [],
      imgSamples: [],
      btnLoading: false,
      btnLoadingDisable: false,
      /**
       * 3 case:
       * 1) ignore: null
       * 2) select from availabels: select
       * 3) upload: upload
       */

      imgAction: null,
      uploadedImgFile: null,
      resolved: false,
      isDone: false,
      eventTablePrice: 500
    }
  },
  methods: {
    ...mapActions({
      addToast: 'addToast',
      fetchItem: 'event/fetchItem',
      clearItem: 'event/clearItem'
    }),
    messageClicked(message) {
      this.formItems['eventMessage'] = message['text']
      this.modals['message'] = false
    },
    receiveImage($event) {
      this.handleSelectImg($event)
      this.imgAction = 'upload'
      this.modals['image'] = false
    },
    async submitForm() {
      if (this.btnLoading || this.isDone) return

      const { eventMessage: message } = this.formItems
      const errors = validateForms({ message }, isFormValid)

      if (errors && errors.length > 0) {
        for (let key of errors) {
          const message = { type: 'error', message: key }
          this.addToast(message)
        }
        return
      }

      this.btnLoading = true

      const finalForm = {}

      finalForm['event_id'] = this.id
      finalForm['text'] = message
      finalForm['include_name'] = this.formItems['eventHasName'] ? 1 : 0

      // Handling Image
      switch (this.imgAction) {
        case 'select':
          finalForm['gallery_id'] = this.imgBase64 ? this.imgBase64.id : ''
          break

        case 'upload':
          try {
            const { data } = await addSampleImages({
              image: this.imgFile
            })
            finalForm['gallery_id'] = data.id
          } catch (ex) {
            if (ex.response) {
              this.addTOast({
                type: 'error',
                message: 'خطایی در ارسال تصویر پیش آمده است.'
              })
            }
            this.btnLoading = false
            return
          }
          break

        default:
          break
      }

      try {
        await changeEvent(finalForm)
        this.isDone = true
        this.btnLoading = false
        this.addToast({
          type: 'success',
          message:
            this.mode === 'edit'
              ? 'تغییرات مورد نظر با موفقیت ثبت گردید.'
              : 'مناسبت با موفقیت فعال شد.'
        })

        this.$store.dispatch('fetchHomeData')
        setTimeout(() => this.$router.push({ name: 'EventHome' }), 2000)
      } catch (ex) {
        if (ex.response) {
          switch (ex.response.status) {
            case 402:
              this.$store.dispatch('profile/handleCreditModal', {
                status: 'open',
                type: 'credit'
              })
              break
            default:
              this.addToast({
                type: 'error',
                message: 'درخواست شما موفقیت‌آمیز نبود.'
              })
          }
        }
      } finally {
        this.btnLoading = false
      }
    },
    async getTextSamples() {
      try {
        const { data } = await getSamples()
        this.samples = data.results
      } catch (ex) {
        console.log(ex)
      }
    },
    async getImageSamples() {
      try {
        const { data } = await getSampleImages()
        this.imgSamples = data.results
      } catch (ex) {
        console.log(ex)
      }
    },
    async disableEvent() {
      this.btnLoadingDisable = true
      try {
        await deleteEvent(this.id)
        this.addToast({
          type: 'success',
          message: 'مناسبت با موفقیت غیرفعال گردید.'
        })
        setTimeout(() => this.$router.push({ name: 'EventHome' }), 2000)
      } catch (ex) {
        if (ex.response) {
          this.addToast({
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
        }
      } finally {
        this.btnLoadingDisable = false
      }
    },
    handleSelectGalleryImg(img) {
      this.imgAction = 'select'
      this.imgBase64 = img
      this.modals['image'] = false
    }
  },
  computed: {
    ...mapState({
      event: (state) => state.event.item,
      pageStatus: (state) => state.event.pageStatus
    }),
    dayLeft() {
      return Math.abs(
        Math.round(
          (new Date() - new Date(this.event['run_at'])) / (24 * 60 * 60 * 1000)
        )
      )
    },
    eventType() {
      return this.event['type'] === '2' ? 'dynamic' : 'static'
    }
  },
  watch: {
    event(newVal) {
      if (newVal) {
        this.resolved = true
        // Initialize form items #TODO make images ready for pagination
        if (newVal['item']) {
          this.formItems['eventHasName'] = newVal['item']['include_name']
          this.formItems['eventMessage'] = newVal['item']['text']
          this.imgAction = 'select'
          this.imgBase64 = this.imgSamples.find(
            (img) => img.id === newVal['item']['gallery']
          )
        }
      }
    }
  },
  created() {
    this.getTextSamples()
    this.getImageSamples()
    this.fetchItem({ part: 'event', id: this.id })
  },
  beforeUnmount() {
    this.clearItem()
  }
}
</script>

<style lang="scss" scoped>
.message-part-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 368px);
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}
.available-messages {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;

  .message-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 92%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    h5 {
      color: #111;
      font-size: 16px;
      margin: 10px 10px 0 0;
    }
  }

  hr {
    width: 92%;
    border-top: 1px solid #aaa;
    margin: 10px 0;
    &:last-child {
      display: none;
    }
  }
}
.modal-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .available-images {
    padding: 20px;
    direction: ltr;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    align-items: center;
    height: 300px;
    .modal__img {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
      img {
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  .upload-wrapper {
    width: 100%;
    height: 100%;
  }
}
.input-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 92%;
  padding: 0;
  .input-row {
    position: relative;
    margin: 0 0 30px;
    padding: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    .message-float-btn {
      z-index: 1;
      position: absolute;
      border-radius: 0 0 15px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 2px);
      height: 60px;
      bottom: 1px;
      cursor: pointer;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #eee;
      svg {
        width: 30px;
        height: 30px;
        fill: #fff;
      }
    }
    p {
      svg {
        width: 20px;
        height: 20px;
        fill: #c69d4e;
      }
      width: 100%;
      text-align: center;
      margin: 0 0;
      span {
        display: inline-block;
        font-size: 12px;
        color: #aaa;
        margin-top: 8px;
      }
    }
  }
  .binary-ans {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .message-btn {
      width: 49%;
      &.active {
        color: #c49d4e;
      }
    }
  }
  .message-btn {
    display: table-cell;
    width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
    font-size: 16px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    color: #000;
    height: 50px;
    line-height: 50px;
    margin: 20px 0 0;
    &.active {
      color: #c69d4e;
    }
    &.confirm {
      background-color: #111;
      color: #fff;
      box-shadow: none;
      outline: none;
    }
  }
}

/** Event Block */
.event {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 8px;
}
.event--short {
  margin-bottom: 15px;
}
.event__title {
  width: 100%;
  margin: 0 0 3px;
  font-size: 20px;
  color: #000;
  text-align: center;
}
.event__time {
  color: #000;
  text-align: right;
  margin: 10px 0 15px;
  animation: blinking 1.3s infinite;
}
.event__time-count {
  color: #c69d4e;
  font-weight: bold;
}
.event__date {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  padding: 0 6px 0 6px;
}
.event__box {
  min-width: 70px;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.1);
  color: #000;
}

.event__date-day {
  margin: 0 0 0;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  top: 2px;
}
.event__date-month {
  margin: 0;
  font-size: 11px;
}

// Img Block
.img {
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 800px;
  color: white;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.img__img {
  width: 100%;
  pointer-events: none;
}
.img__outer {
  // use to set height
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
}
.img__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 100%;
  background-color: #ddd;
  pointer-events: none;
}
.img__icon {
  width: 40px;
  height: 40px;
  fill: #fff;
  pointer-events: none;
}
.img__text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
  pointer-events: none;
}
.img__remove {
  display: block;
  text-align: center;
  width: 100%;
  height: 50px;
  color: #777;
  z-index: 1;
  font-size: 20px;
}

.round-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0 50px;
  padding: 0;
}
.round-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  cursor: pointer;
  position: relative;
  outline: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 5px;
}
.round-btn__text {
  color: #000;
  display: inline-block;
  position: absolute;
  width: 100px;
  text-align: center;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
}
.round-btn__icon {
  width: 35px;
  height: 35px;
  fill: #555;
}

// change in common css
.credit-buttons {
  justify-content: center;
  margin: 30px 0 30px;
}
.buttons--add {
  margin: 30px 0;
}
.buttons--edit {
  margin: 30px 0 30px;
  justify-content: space-between;
}
.buttons--edit .button--black {
  width: calc(50% - 5px);
}
.button--black {
  width: 100%;
}
.button--default {
  background-color: #ddd;
  width: 220px;
  height: 70px;
}
.button--default .button__text {
  color: #111;
  font-size: 16px;
  margin-left: 10px;
}
.button--default .button__icon {
  fill: #fff;
  background-color: #fff;
  border-radius: 4px;
  // border: 1px solid #777;
}
.button--active {
  background-color: #000;
}
.button--active .button__icon {
  fill: #fff;
  background-color: #222;
  border-radius: 4px;
  border: none;
}
.button--active .button__text {
  color: #fff;
}
.button--remove {
  background-color: rgba(0, 0, 0, 0.08);
  width: calc(50% - 5px);
}
.button--remove .button__text {
  color: #111;
}
</style>
