import http from './HttpService'

export const getSampleImages = () => {
  return http.get('/store/gallery/')
}

export const addSampleImages = (data) => {
  const formData = new FormData()
  formData.append('image', data['image'])

  return http.post('/store/gallery/', formData)
}

export const addNotice = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.post('/store/notice/', formData)
}
