<template>
  <div class="stat-wrapper">
    <section class="load-pending" v-if="!resolved">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div class="chartTitle">
        <h3 class="chartTitle__text">تعداد پیام ‌های ارسال شده</h3>
        <h4 class="chartTitle__number">
          {{ stat.count ? stat.count.toLocaleString() : 0 }}
        </h4>
      </div>
      <div class="stat-table">
        <div class="stat-table__row header--row">
          <h5 class="stat-table__cell">تاریخ</h5>
          <h5 class="stat-table__cell">زمان</h5>
          <h5 class="stat-table__cell">تعداد</h5>
          <h5 class="stat-table__cell">مناسبت</h5>
        </div>
        <div
          v-for="(event, idx) in eventLogs"
          :key="event.id"
          :class="['stat-table__row', idx % 2 === 0 && 'even--row']"
        >
          <h5 class="stat-table__cell">
            {{ localizeDate(event['created_at']) }}
            <h5 class="stat-table__cell">{{ getHour(event['created_at']) }}</h5>
          </h5>
          <h5 class="stat-table__cell">
            {{ event['count'].toLocaleString() }}
          </h5>
          <h5 class="stat-table__cell cell--fa-text">{{ event['name'] }}</h5>
        </div>
        <div class="stat-table__row" v-if="eventLogs.length < 1">
          <h5 class="stat-table__cell cell--fa-text">ارسال نشده</h5>
          <h5 class="stat-table__cell">
            0
          </h5>
          <h5 class="stat-table__cell">0</h5>
          <h5 class="stat-table__cell">
            0
          </h5>
        </div>
      </div>
    </section>
    <!-- <failed-mode @refresh="getStats" v-else /> -->
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import dateConvertor from '@composable/dateConvertor'

export default {
  name: 'EventStat',
  setup() {
    const { localizeDate, getHour } = dateConvertor()
    const store = useStore()
    const stat = computed(() => store.state.stat)
    const eventLogs = computed(() => store.state.event.logs)
    // use to resolved data fetching to show page
    const resolved = ref(false)
    const getStat = () => {
      store.dispatch('fetchStat', { part: 'event' })
      store.dispatch('event/fetchEventLogs')
    }
    getStat()

    watchEffect(() => {
      const cond1 = stat.value && stat.value.count >= 0
      resolved.value = cond1
    })

    onBeforeUnmount(() => store.dispatch('clearStat'))

    return { resolved, eventLogs, localizeDate, getHour, stat, getStat }
  }
}
</script>

<style scoped lang="scss">
.stat-wrapper {
  width: 100%;
  padding: 5px 0 20px;
  // background: linear-gradient(to bottom, #000 30%, #333 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

// table
.stat-table__row {
  grid-template-columns: repeat(4, 1fr);
}
</style>
