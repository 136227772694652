<template>
  <form novalidate class="form-wrapper" @submit.prevent="submitForm">
    <div class="credit-buttons" style="margin: 20px 0 30px;">
      <button
        type="button"
        class="button button--form"
        @click="modals['lastBuy'] = true"
      >
        <span :class="['button__text']">
          تاریخ آخرین خرید
        </span>
        <span v-show="formItems['lastBuy']">:</span>
        <!-- <span class="button__text" v-show="!formItems['lastBuy']">
          تعیین کنید
        </span> -->
        <span class="button__text active" v-show="formItems['lastBuy']">
          {{ formItems['lastBuy'] }}
        </span>
        <span
          class="button__text active"
          style="font-size: 16px;"
          v-show="formItems['lastBuy']"
        >
          روز پیش
        </span>
      </button>
    </div>
    <div class="has-name">
      <p class="has-name__text">
        "نام مشتری" به ابتدای پیام افزوده ‌شود؟
      </p>
      <span class="has-name__ex">
        مثال: "خانم سارا محمدی عزیز" یا " آقای علی رضایی عزیز"
      </span>
      <div class="credit-buttons" style="margin: 0">
        <button
          @click="formItems['hasName'] = !formItems['hasName']"
          type="button"
          :class="[
            'button',
            'button--default',
            formItems['hasName'] && 'button--active'
          ]"
        >
          <span class="button__text">
            {{ formItems['hasName'] ? 'بله' : 'خیر' }}
          </span>
        </button>
      </div>
    </div>
    <div class="bta-wrapper">
      <box-textarea
        label="متن پیام"
        animateType="remove"
        width="100%"
        v-model="formItems['message']"
      />
    </div>
    <!-- <div class="table table--form" style="margin: 30px 0 0;">
      <div class="table__row">
        <h6 class="table__cell cell--title">
          ارسال هر پیام
        </h6>
        <h6 class="table__cell cell--price ">
          {{ reminderTablePrice.toLocaleString() }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row row--desc">
        مبلغ ارسال پیام ‌ها در زمان ارسال، به ازای تعداد مشتریان محاسبه شده و از
        موجودی پرداخت می‌گردد.
      </div>
    </div> -->
    <div class="credit-buttons" v-if="mode === 'new'">
      <button type="submit" class="button button--black">
        <span class="button__text">فعالسازی</span>
        <spinner v-if="btnLoading" />
      </button>
    </div>
    <div class="round-btns" v-else-if="mode === 'edit'">
      <button type="button" class="round-btn" @click="disableReminder">
        <spinner v-if="btnLoadingDisable" />
        <svg
          class="round-btn__icon"
          v-else
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
          viewBox="0 0 700 512"
        >
          <path
            d="M279.81 1.13l140.38 0c8.66,0 15.75,7.08 15.75,15.74l0 0c0,8.66 -7.09,15.75 -15.75,15.75l-140.38 0c-8.66,0 -15.75,-7.09 -15.75,-15.75l0 0c0,-8.66 7.09,-15.74 15.75,-15.74zm254.83 99.61l0 358.4c0,28.45 -23.28,51.73 -51.73,51.73l-267.02 0c-28.45,0 -51.73,-23.28 -51.73,-51.73l0 -358.4 -39.2 0c-8.65,0 -15.74,-7.08 -15.74,-15.74l0 0c0,-8.66 7.09,-15.74 15.74,-15.74l450.08 0c8.65,0 15.74,7.08 15.74,15.74l0 0c0,8.66 -7.09,15.74 -15.74,15.74l-40.4 0zm-258.35 292.43l0 -207.33c0,-8.67 7.08,-15.75 15.74,-15.75l0 0c8.66,0 15.75,7.08 15.75,15.75l0 207.33c0,8.66 -7.09,15.75 -15.75,15.75l0 0c-8.66,0 -15.74,-7.09 -15.74,-15.75zm114.38 0l0 -207.33c0,-8.67 7.08,-15.75 15.74,-15.75l0 0c8.66,0 15.74,7.08 15.74,15.75l0 207.33c0,8.66 -7.08,15.75 -15.74,15.75l0 0c-8.66,0 -15.74,-7.09 -15.74,-15.75zm112.48 -292.43l0 350.64c0,15.4 -12.6,28 -28,28l-251.5 0c-15.4,0 -28,-12.6 -28,-28l0 -350.64 307.5 0z"
          />
        </svg>
        <span class="round-btn__text">لغو ارسال</span>
      </button>
      <button type="submit" class="round-btn">
        <span class="round-btn__text">ثبت تغییرات</span>
        <spinner v-if="btnLoading" />
        <svg class="round-btn__icon" v-else viewBox="0 0 512 512">
          <path
            d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
          />
        </svg>
      </button>
    </div>
    <modal
      :is-open="modals['lastBuy']"
      @modalClosed="modals['lastBuy'] = false"
      title="روز پیش"
    >
      <ul class="items-wrapper">
        <li v-for="day in lastBuyDays" :key="day">
          <a
            :class="day === formItems['lastBuy'] && 'active'"
            @click="
              () => {
                formItems['lastBuy'] = day
                modals['lastBuy'] = false
              }
            "
          >
            {{ day }} <span>روز پیش</span>
          </a>
        </li>
      </ul>
    </modal>
  </form>
</template>

<script>
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  validateForms,
  schemaMaker,
  reqString,
  modalNumber
} from '@valid/JoiValidation'
import {
  addReminder,
  editReminder,
  deleteReminder
} from '@service/ReminderService'
import BoxTextarea from '@common/BoxTextarea'

export default {
  name: 'ReminderNew',
  components: {
    BoxTextarea
  },
  props: ['mode', 'id'],
  setup(props) {
    const store = useStore()
    const router = useRouter()
    // Initialization
    const reminderTablePrice = 500
    const btnLoading = ref(false)
    const isDone = ref(false)
    const btnLoadingDisable = ref(false)
    const modals = reactive({
      lastBuy: false
    })
    const lastBuyDays = [
      ...Array.from({ length: (365 - 1) / 1 + 1 }, (_, i) => 1 + i * 1)
    ]
    const formItems = reactive({
      lastBuy: '',
      message: '',
      hasName: true
    })
    const reminder = computed(() => store.state.reminder.item)
    // Initial call
    if (props.mode === 'edit') {
      store.dispatch('reminder/fetchItem', { part: 'reminder', id: props?.id })
    } else if (props.mode !== 'new') {
      router.push({ name: 'ReminderHome' })
    }
    // Set formItems
    watch(reminder, (reminder) => {
      if (reminder) {
        formItems['hasName'] = reminder['include_name']
        formItems['lastBuy'] = reminder['last_buy']
        formItems['message'] = reminder['message']
      }
    })

    // Validation
    const isValid = schemaMaker({
      message: reqString('متن پیام'),
      lastBuy: modalNumber('زمان آخرین خرید', 1)
    })
    // Submition
    const submitForm = async () => {
      if (btnLoading.value || isDone.value) return

      const { message, lastBuy } = formItems
      const errors = validateForms({ message, lastBuy }, isValid)
      if (errors && errors.length > 0) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }
      btnLoading.value = true

      // Conditional submition
      if (props.mode === 'new') {
        try {
          await addReminder(formItems)
          isDone.value = true
          store.dispatch('addToast', {
            type: 'success',
            message: 'یادآوری با موفقیت ایجاد گردید.'
          })

          store.dispatch('fetchHomeData')
          setTimeout(() => router.push({ name: 'ReminderHome' }), 2000)
        } catch (ex) {
          if (ex.response) {
            switch (ex.response.status) {
              case 402:
                store.dispatch('profile/handleCreditModal', {
                  status: 'open',
                  type: 'credit'
                })
                break
              default:
                store.dispatch('addToast', {
                  type: 'error',
                  message: 'درخواست شما موفقیت‌آمیز نبود.'
                })
            }
          }
        } finally {
          btnLoading.value = false
        }
      } else if (props.mode === 'edit') {
        try {
          await editReminder(props.id, formItems)
          isDone.value = true
          store.dispatch('addToast', {
            type: 'success',
            message: 'ویرایش با موفقیت انجام شد.'
          })
          setTimeout(() => router.push({ name: 'ReminderHome' }), 2000)
        } catch (ex) {
          if (ex.response) {
            store.dispatch('addToast', {
              type: 'error',
              message: 'درخواست شما موفقیت‌آمیز نبود.'
            })
          }
        } finally {
          btnLoading.value = false
        }
      }
    }

    // Delete reminder
    const disableReminder = async () => {
      if (btnLoadingDisable.value) return

      btnLoadingDisable.value = true
      try {
        await deleteReminder(props.id)
        btnLoadingDisable.value = false
        store.dispatch('addToast', {
          type: 'success',
          message: 'یادآوری با موفقیت حذف گردید.'
        })
        router.push({ name: 'ReminderHome' })
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
          btnLoadingDisable.value = false
        }
      }
    }

    // Remove reminder
    onBeforeUnmount(() => store.dispatch('reminder/clearItem'))

    return {
      reminder,
      formItems,
      lastBuyDays,
      btnLoading,
      btnLoadingDisable,
      modals,
      submitForm,
      disableReminder,
      reminderTablePrice
    }
  }
}
</script>

<style lang="scss" scoped>
.bta-wrapper {
  width: 90%;
}

.available-messages {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .message-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 92%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    h5 {
      color: #111;
      font-size: 16px;
      margin: 10px 10px 0 0;
    }
  }

  hr {
    width: 92%;
    border-top: 1px solid #aaa;
    margin: 10px 0;
    &:last-child {
      display: none;
    }
  }
}
// change in common css
.form-wrapper {
  // width: 90%;
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}
.has-name {
  width: 90%;
}
.credit-buttons {
  justify-content: center;
  margin: 30px 0;
}
.button--black {
  width: 90%;
}
.button--default {
  background-color: #ddd;
  width: 220px;
  height: 70px;
}
.button--default .button__text {
  color: #111;
  font-size: 16px;
  margin-left: 10px;
}
.button--default .button__icon {
  fill: #fff;
  background-color: #fff;
  border-radius: 4px;
  // border: 1px solid #777;
}
.button--active {
  background-color: #000;
}
.button--active .button__icon {
  fill: #fff;
  background-color: #222;
  border-radius: 4px;
  border: none;
}
.button--active .button__text {
  color: #fff;
}
.button--form {
  margin: 0;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}
.buttons--edit {
  width: 90%;
  margin: 30px 0;
  justify-content: space-between;
}
.buttons--edit .button--black {
  width: calc(50% - 5px);
}
.button--remove {
  background-color: rgba(0, 0, 0, 0.08);
  width: calc(50% - 5px);
}
.button--remove .button__text {
  color: #111;
}
</style>
